import "./index.scss";

import { Controller as BaseController } from "stimulus";
import Cookies from "js-cookie";
import Sortable from "sortablejs";
import { patch } from "@rails/request.js";

export default class extends BaseController {
  static values = {
    hasChildren: Boolean,
    expandedIcon: String,
    collapsedIcon: String,
    dragHandle: String,
    updatePreferencesUrl: String,
    childrenOrder: Array,
  };

  static classes = ["collapsed", "activeLink", "asideHidden", "asideExpandedItem", "navHiddenItem"];

  static targets = [
    "titleElement",
    "childrenElement",
    "collapsibleIcon",
    "hrefElement",
    "collapsedIconElement",
    "collapsedMenuElement",
    "visibilityCheckbox",
  ];

  connect() {
    this.element.controller = this;
    if (this.#currentPageActive()) {
      this.#expandChildren(true);
    } else {
      this.#disableActiveLink();
    }

    this.toggleAside(Cookies.get("aside_collapsed") === "true");
    this.collapseAsideHidden();
  }

  disconnect() {}

  toggleAside(toggled) {
    if (toggled) {
      this.element.classList.add(this.asideHiddenClass);
      this.collapsedIconElementTarget.classList.remove("hidden");
      if (this.hasCollapsedMenuElementTarget) {
        this.collapsedMenuElementTarget.classList.remove("hidden");
      }
    } else {
      this.element.classList.remove(this.asideHiddenClass);
      this.collapsedIconElementTarget.classList.add("hidden");
      this.element.classList.remove(this.asideExpandedItemClass);
      if (this.hasCollapsedMenuElementTarget) {
        this.collapsedMenuElementTarget.classList.add("hidden");
      }
    }
  }

  toggle(event) {
    if (
      this.hasChildrenValue &&
      this.hasChildrenElementTarget &&
      (this.titleElementTarget.contains(event.target) || this.collapsibleIconTarget.contains(event.target))
    ) {
      this.#toggleChildren();
    }
  }

  expandAsideHidden() {
    if (!this.hasChildrenValue) {
      this.hrefElementTarget.click();
      return;
    }

    // FIXME: Refactor this and other places to stop relying on document.querySelector and instead use dispatch events or outlets
    for (const element of document.querySelectorAll(`[data-controller='${this.identifier}']`)) {
      element.controller.collapseAsideHidden();
    }
    this.element.classList.add(this.asideExpandedItemClass);

    if (this.hasCollapsedMenuElementTarget) {
      for (const element of this.collapsedMenuElementTarget.children) {
        element.classList.remove("hidden");
      }
    }
    this.dispatch("toggleAsideHidden", {
      detail: { expanded: true },
    });
  }

  collapseAsideHidden() {
    this.element.classList.remove(this.asideExpandedItemClass);
    if (this.hasCollapsedMenuElementTarget) {
      for (const element of this.collapsedMenuElementTarget.children) {
        element.classList.add("hidden");
      }
    }
  }

  childrenElementTargetConnected(element) {
    // Maps children default order based on data-id shown order on code and data-default-order attr
    const childrenDefaultOrder = {};
    for (let child of element.querySelectorAll("[data-default-order]")) {
      childrenDefaultOrder[child.dataset.id] = child.dataset.defaultOrder;
    }

    this.sortable = new Sortable(element, {
      group: element.id,
      disabled: false, // Disables the sortable if set to true.
      store: {
        /**
         * Get the order of elements. Called once during initialization.
         * @param   {Sortable}  sortable
         * @returns {Array}
         */
        get: function (sortable) {
          if (this.childrenOrderValue.length === 0) {
            return this.childrenOrderValue;
          }

          // For any unknown items(which were not persisted before), which means either a new dynamic item,
          // or a new menu item that was added to the component
          // we set their default order based on the data-default-order attribute
          for (let order in childrenDefaultOrder) {
            if (!this.childrenOrderValue.includes(order)) {
              if (childrenDefaultOrder[order] === "bottom") {
                this.childrenOrderValue.push(order);
              } else {
                this.childrenOrderValue.unshift(order);
              }
            }
          }
          return this.childrenOrderValue;
        }.bind(this),
        /**
         * Save the order of elements. Called onEnd (when the item is dropped).
         * @param {Sortable}  sortable
         */
        set: function (sortable) {
          patch(this.updatePreferencesUrlValue, {
            body: { item: sortable.options.group.name, order: sortable.toArray(), setting: "order" },
          });
        }.bind(this),
      },
      animation: 150, // ms, animation speed moving items when sorting, `0` — without animation
      handle: this.dragHandleValue, // Drag handle selector within list items
      preventOnFilter: true, // Call `event.preventDefault()` when triggered `filter`
      ghostClass: "bg-gray-300", // Class name for the drop placeholder
      direction: "vertical", // Direction of Sortable (will be detected automatically if not given)
    });
  }

  hrefElementTargetConnected(element) {
    if (
      this.hasChildrenValue &&
      (!element.hasAttribute("href") || element.getAttribute("href") === "" || element.getAttribute("href") === "#")
    ) {
      // We get the first child that has a href and set it as the href for the parent if child is visible
      for (let child of this.childrenElementTarget.querySelectorAll("a[href]:not([href='#'])")) {
        if (child.getAttribute("href") !== "#" && child.getAttribute("href") !== "" && child.offsetParent !== null) {
          this.hrefElementTarget.setAttribute("href", child.getAttribute("href"));
          if (child.getAttribute("target")) {
            this.hrefElementTarget.setAttribute("target", child.getAttribute("target"));
          }
          if (child.getAttribute("data-turbo-stream")) {
            this.hrefElementTarget.setAttribute("data-turbo-stream", child.getAttribute("data-turbo-stream"));
          }

          break;
        }
      }
    }
  }

  enableParentActiveLink() {
    // FIXME: Refactor this, there is a lot of duplicated logic between this and #expandChildren...
    const parentElement = this.element.parentElement;
    if (parentElement !== null) {
      const parentControllerElement = parentElement.closest(`[data-controller=${this.identifier}]`);
      if (parentControllerElement !== null && parentControllerElement.controller !== undefined) {
        parentControllerElement.controller.hrefElementTarget.classList.add(this.activeLinkClass);
        parentControllerElement.controller.enableParentActiveLink();
        parentControllerElement.controller.childrenElementTarget.classList.remove(this.collapsedClass);
        if (parentControllerElement.controller.hasCollapsibleIconTarget) {
          parentControllerElement.controller.collapsibleIconTarget.classList.remove(this.collapsedIconValue);
          parentControllerElement.controller.collapsibleIconTarget.classList.add(this.expandedIconValue);
        }
      }
    }
  }

  enableActiveLink() {
    // Quick fix to disable all active links before enabling the current one, we should use outlets or dispatch events instead
    for (let element of document.querySelectorAll(`.${this.activeLinkClass}`)) {
      element.classList.remove(this.activeLinkClass);
    }
    this.hrefElementTarget.classList.add(this.activeLinkClass);
    this.enableParentActiveLink();
  }

  #disableActiveLink() {
    this.hrefElementTarget.classList.remove(this.activeLinkClass);
  }

  #isCollapsed() {
    return this.hasChildrenValue && this.childrenElementTarget.classList.contains(this.collapsedClass);
  }

  visibilityCheckboxTargetConnected(element) {
    // We need to add this event listener to stop the click event from propagating
    // to the parent element(which is a href and will trigger navigation)
    element.addEventListener("click", (event) => {
      event.stopPropagation();
    });
  }

  updateVisibility(event) {
    if (event.target.checked) {
      this.element.classList.remove(this.navHiddenItemClass);
    } else {
      this.element.classList.add(this.navHiddenItemClass);
    }

    patch(this.updatePreferencesUrlValue, {
      body: { item: event.target.name, setting: "visibility", visibility: event.target.checked ? 1 : 0 },
    });
  }

  #collapseChildren() {
    if (!this.#isCollapsed()) {
      this.childrenElementTarget.classList.add(this.collapsedClass);
      if (!this.hasCollapsibleIconTarget) {
        return;
      }
      this.collapsibleIconTarget.classList.remove(this.expandedIconValue);
      this.collapsibleIconTarget.classList.add(this.collapsedIconValue);
    }
  }

  #expandChildren(enableParentActiveLink = false) {
    if (enableParentActiveLink) {
      this.enableParentActiveLink();
    }
    if (this.#isCollapsed()) {
      this.childrenElementTarget.classList.remove(this.collapsedClass);
      if (!this.hasCollapsibleIconTarget) {
        return;
      }
      this.collapsibleIconTarget.classList.remove(this.collapsedIconValue);
      this.collapsibleIconTarget.classList.add(this.expandedIconValue);
    }
  }

  #toggleChildren() {
    this.#currentPageActive();

    if (!this.hasChildrenElementTarget) {
      return;
    }

    if (this.#isCollapsed()) {
      this.#expandChildren();
    } else {
      this.#collapseChildren();
    }
  }

  #currentPageActive() {
    const activeHrefList = this.hrefElementTarget.getAttribute("data-active-href-list") + ",";
    const hrefUrl = new URL(this.hrefElementTarget.getAttribute("href"), window.location.origin);
    let currentPage = false;
    if (
      hrefUrl.pathname + hrefUrl.search === window.location.pathname + window.location.search ||
      hrefUrl.href === window.location.href ||
      (activeHrefList && activeHrefList.includes(window.location.href + ","))
    ) {
      this.hrefElementTarget.classList.add(this.activeLinkClass);
      currentPage = true;
    }

    return currentPage;
  }
}
